import { DesignerElement, DesignerImage, DesignerText, DesignerDate, DesignerRect, DesignerQRCode} from "./DesignerElements";
import { API }  from "./API";
import { ElementTools, Hover, PopupToggle } from "./DesignerPopup";
import { APIResponse, Border, Bounds, ColorObject, ColorSet, Config, Dater, DesignerType, FontObject, LineProperties, MCISpacingError, Product, RenderMode, SaveObject, SendFileResponse, SendImageError, SendImageResponse, SendImageVKResponse, TextSpecials, UndoObject, VKData } from "./TypescriptInterfaces";
import { DesignData, ElementError, SaveData, Utility } from "./Utility";
import { TabInterface } from "./TabInterface";
import { Input } from "./Input";
import { DialogInterface } from "./DialogInterface";
import { DesignerRenderer } from "./DesignerRenderer";
import { DesignerPatternController } from "./DesignerPatternController";
import { Debug } from "./Debug";
import { error } from "jquery";

//Base designer class, used for controling and managing
class DesignerController{
    formatVersion = "2.4";
    designerDiv: HTMLDivElement;
    canvas: HTMLCanvasElement;
    canvasDIV: HTMLDivElement;
    ctx: CanvasRenderingContext2D;   
    
    scaleY = 80;
    desktop = true;
    idStep = 0;
    MCI = false;
    twoTone = false;
    //type = "print";
    type: DesignerType = "stamp";
    dater: Dater;
    dateElement: DesignerDate|null = null;

    //Tab data
    border: Border = {
        active: false,
        padding: 0.25,
        width: 0.25,
        double: false,
        style: "solid",
        color: "#000000"
    };
    preScaleBorderWidth: number|null = null;
    preScaleBorderPadding: number|null = null;
    lineSpawn: LineProperties = {
        orientation: "horizontal",
        width: 0.25,
        length: 10
    }
    imageFullWidth: number;

    //GRID
    showGrid = false;
    snapToGrid = false;
    gridSpaceing = 2;
    gridLineWidth = 1;

    //UNDO
    historyUndo:Array<UndoObject> = [];
    historyRedo:Array<UndoObject> = [];
    undoTimer:number;

    //Interface
    zoom = 1;
    scaling = 1;
    tabInterface: TabInterface;
    input: Input;
    dialogInterface: DialogInterface;
    utility: Utility;
    config: Config;
    vkdata: VKData;
    doPriceUpdate:boolean = false;

    fonts:Array<string> = [];
    fontsBold:Array<string> = [];
    fontsItalic:Array<string> = [];

    API: API;
    renderer: DesignerRenderer;
    patternController: DesignerPatternController;
    HTMLData: any;
    loading = true;
    inkpadColor = "#000000";

    //Enumerated object
    MCIColors: ColorSet;

    shownAlerts:Array<string> = [];
    welcomeData: any = null;

    localStoragePattern: string |null;

    //Element
    elements: Array<DesignerElement> = [];
    activeElementTools: ElementTools|null = null;
    activeHover: Hover|null = null;
    warningIcon: HTMLImageElement;
    
    //Padding for cut lines (for exmaple date field)
    cutPadding = 0.7;

    constructor(config: any){
        this.config = config;
        this.vkdata = {
            url: null,
            urlEdit: null,
            firma : "",
            slogan: "",
            name : "",
            strasse : "",
            plz : "",
            ort : "",
            land : "",
            telefon : "",
            telefax : "",
            mobil : "",
            email : "",
            web : ""
        };
    }

    //initilize designer
    async init(){
        return new Promise(async (resolve, reject) => {
            this.utility = new Utility(this);

            this.designerDiv = document.getElementById('designer') as HTMLDivElement;
            this.designerDiv.classList.add("loading");

            //Setting canvas dimensions
            this.canvasDIV = document.getElementById('canvas') as HTMLDivElement;
            let canvasData = this.createCanvas("designer-canvas");
            if(!canvasData){
                reject('Couldnt intitialize canvas');
                return;
            }
            this.canvas = this.canvasDIV.appendChild(canvasData.canvas);

            this.ctx = canvasData.context

            //Image quality settings
            this.ctx.imageSmoothingEnabled = true;
            if(this.ctx.imageSmoothingQuality){
                this.ctx.imageSmoothingQuality = "high";
            }

            this.setCanvasDimensions();
            
            //Read parameters out of HTML Data
            if(this.designerDiv){
                let dataset = this.designerDiv.dataset;
                this.config.dataset = dataset as any;

                if(this.config.dataset.kdnr == undefined 
                || this.config.dataset.tid == undefined
                || this.config.dataset.mode == undefined){
                    console.warn('Missing API Parameters in DIV with id "designer" - might cause designer to not properly load');
                }

                //Load API Connection
                this.API = new API(this.config.APILegacy, this.config.APIKey, this.config.dataset.kdnr, this.config.dataset.knr);

                this.tabInterface = new TabInterface(this, this.config, this.API);
                await this.API.loadHTMLContent(this);

                this.input = new Input(this);
                this.input.initListeners();

                this.dialogInterface = new DialogInterface(this);
                this.patternController = new DesignerPatternController(this);
                
                await this.loadFontList();
                await this.APIinit(this.config.dataset.prototyp, this.config.dataset.tid,  this.config.dataset.canvas,  this.config.dataset.mode, this.config.dataset.artikel);
                this.loadingDone();
            }else{
                reject('Cant find designer DIV');
                return;
            }
            resolve(true);
            this.renderer = new DesignerRenderer(this.ctx, this.canvas, this);
            window.requestAnimationFrame(() => this.renderer.render());

            Debug.TemplateLoadStringLegacy(this.patternController);
        });
    }

    //API Request with "init"
    async APIinit(pid:string, tid: string, canvasid: string, mode: string, article: string){
        let bonusParams:any = {
            tid: tid,
            mode: mode,
            userCode: ""
        };

        if(pid != ""){
            bonusParams.pid = pid;
        }
        if(canvasid != ""){
            bonusParams.canvasid = canvasid;
        }
        if(article != ""){
            bonusParams.article = article;
        }else{
            bonusParams.article = 0;
        }

        this.localStoragePattern = localStorage.getItem("designer2021_last_pattern");
        
        this.API.request("init", bonusParams).then((data) => {
            this.loadInitConfig(data);

            if( (this.config.dataset.mode == "loadcanvas" || this.config.dataset.mode == "shop" || this.config.dataset.mode == "s4y" || this.config.dataset.mode == "") && this.config.init.startPage == "true"){
                this.showWelcomeScreen(data);
            }else{
                this.patternController.loadStartPattern(data, false);
            }

            this.generateInkpadColors(data.color.pad);

            //Set start tab to the tab in init
            switch(this.config.init.entrancePage){
                default: this.tabInterface.showActiveTab(); break;
                case "muster": this.input.switchTab("toolbar_pattern", true); break;
            }
            
            //Disable scaling for date
            if(this.patternController.dateIsSet()){
                let slider = document.getElementsByClassName('scale_slider_div')[0];

                if(slider){
                    slider.remove()
                }
                let fill = document.getElementsByClassName('scale_fill_div')[0];
                if(fill){
                    fill.remove()
                }

                if(this.MCI){
                    this.addMCIDateColorPicker();
                }
            }

            
        });
        //Load warning icon
        Utility.loadImage(this.config.imagePath+"warning_amber_24dp.svg").then( image => {
            this.warningIcon = image;
        });
    }

    //Load all the settings from the init request
    loadInitConfig(data: any){
        this.config.init = data.config;
        this.config.userPattern = data.userPattern;

        if(data.config.text.maxRows <= 0 && data.config.text.minRows <= 0){  
            document.getElementById('toolbar_text')?.remove();
        }
        if(data.userPattern.allowed == "false"){
            document.getElementById('toolbar_pattern')?.remove();
        }
        if(data.config.images.allowed == "false"){
            document.getElementById('toolbar_image')?.remove();
        }
        if(data.config.layout.allowed == "false"){
            document.getElementById('toolbar_full')?.remove();
        }
        if(data.config.pattern.allowed == "false"){
            document.getElementById('toolbar_pattern')?.remove();
        }
        if(data.config.lines.allowed == "false"){
            document.getElementById('toolbar_border')?.remove();
        }
        if(data.userPattern.allowed == "false"){
            document.getElementById('toolbar_userpattern')?.remove();
        }

        //Temp
        if (data.config.deleteLayout === 'false') {
            document.getElementById('toolbar_userpattern')?.remove();
            document.getElementById('delete_all')?.remove();
        }

        //Grid
        if(data.config.helpLines.show == "true"){
            this.showGrid = true;
        }
        this.gridSpaceing = Number.parseFloat(data.config.helpLines.accuracy);
        this.gridLineWidth = Number.parseFloat(data.config.helpLines.lineWidth);

        if(this.config.borderSpacingStart){
            this.border.padding = this.config.borderSpacingStart;
        }

        this.MCIColors = data.color.mci;
        if (data.typ == 'MCI') {
            this.MCI = true;
            this.border.padding = Number.parseFloat(data.config.mci.spaceBetween) + 0.25;
        }
        
        this.config.widthMM = Number.parseFloat( data.dimension.width );
        this.config.heightMM = Number.parseFloat( data.dimension.height );
        this.config.maxWidthMM = Number.parseFloat( data.dimension.maxWidth );
        this.config.maxHeightMM = Number.parseFloat( data.dimension.maxHeight );
        this.config.minWidthMM = Number.parseFloat( data.dimension.minWidth );
        this.config.minHeightMM = Number.parseFloat( data.dimension.minHeight );
        this.config.typ = data.typ;
        this.config.userCode = this.getUserCode(data.userCode);

        this.setCanvasDimensions();

        let right_area = document.getElementsByClassName("right_area")[0] as HTMLDivElement;
        right_area.style.width = (this.config.scalingTargetWidth+8)+"px";
        right_area.style.maxWidth = (this.config.scalingTargetWidth+8)+"px";

        //Not yet live
        document.getElementById('toolbar_qr')?.remove();

        if(data.calculation == true){
            this.doPriceUpdate = true;
        }

        if(this.config.maxWidthMM < this.config.QRMinWidth|| this.config.maxHeightMM < this.config.QRMinHeight){
            document.getElementById('toolbar_qr')?.remove();
        }

        this.patternController.loadDateField(data);

        Debug.SetSVGButton(this.config.dataset.tid, this.config.API);
    }

    //Show welcome screen div as overlay (using workaround for sizing and position)
    showWelcomeScreen(data: any){
        this.welcomeData = data;
        let designerEditor = document.getElementById("designer_editor");
        if(!designerEditor){
            return false;
        }
        let welcomeDIV = document.createElement("div");
        welcomeDIV.classList.add("welcome_screen");
        welcomeDIV.innerHTML = this.HTMLData.welcome;

        designerEditor.appendChild(welcomeDIV);

        let _this = this;

        let loadTextButton = document.getElementById("welcome_load_text");
        loadTextButton?.addEventListener("click", () => {
            _this.patternController.loadStartPattern(data, false);
            _this.tabInterface.activeTab = "text";
            _this.tabInterface.showActiveTab();
            _this.closeWelcomeScreen();
        });

        

        let loadPatternButton = document.getElementById("welcome_load_pattern");

        if(data.config.layout.allowed == "false"){
            loadPatternButton?.remove();
        }

        if(loadPatternButton){
            loadPatternButton?.addEventListener("click", () => {
                _this.patternController.loadStartPattern(data, false);
                _this.tabInterface.activeTab = "pattern";
                _this.tabInterface.showActiveTab();
                _this.closeWelcomeScreen();
            });
        }

        let loadFullButton = document.getElementById("welcome_load_full");

        if(data.config.layout.allowed == "false"){
            loadFullButton?.remove();
        }
        
        if(loadFullButton){
            loadFullButton.addEventListener("click", () => {
                //Load default, then clear non-essential elements (keep date)
                _this.patternController.loadStartPattern(data, false);
                _this.clearElements(false, false);
    
                _this.tabInterface.activeTab = "full";
                _this.tabInterface.showActiveTab();
    
                _this.closeWelcomeScreen();
            });
        }

        let loadLocalButton = document.getElementById("welcome_load_local");
        if(loadLocalButton){
            if(this.localStoragePattern){
                loadLocalButton.addEventListener("click", () => {
                    _this.patternController.loadStartPattern(data, true);
                    _this.closeWelcomeScreen();
                });
            }else{
                loadLocalButton.title = "Öffnen sie zuvor ein Muster um diese Funktion zu nutzen.";
                loadLocalButton.classList.add("welcome_button_inactive")
            }
        }
    }

    //Close welcome screen overlay
    closeWelcomeScreen(){
        let welcomeDIV = document.getElementsByClassName("welcome_screen")[0];
        if(welcomeDIV){
            welcomeDIV?.remove();
        }
    }

    //Create HTML5 canvas
    createCanvas(id: string) : false | {context: CanvasRenderingContext2D; canvas: HTMLCanvasElement} {
        let canvas = document.createElement('canvas');
        canvas.id = id;
        
        let ctx = canvas.getContext('2d');
        if(!ctx){
            return false;
        }

        ctx.textBaseline = "alphabetic";
        //Lines needs to be offset for sub-pixel drawing
        ctx.translate(10.5,50.5);

        return {
            context: ctx,
            canvas: canvas
        }
    }

    //When all loading is done
    loadingDone(){
        this.loading = false;
        this.designerDiv.classList.remove("loading");
        document.getElementById('loadingCircle')?.remove();
    }

    //Finish desgining and upload to server as finished version
    async finish(){
        let errors = await this.displayErrorAlerts();

        if(errors){
            return false;
        }
        
        //Calculate dimensions
        let bounds = this.calcBounds(this.canvas);
        bounds = this.roundBounds(bounds);

        //Rounding?
        let width = Math.ceil(bounds.w/this.config.pixelToMM);
        let height = Math.ceil(bounds.h/this.config.pixelToMM);

        let products:any = null;
        if(!this.config.dataset.artikel || this.config.dataset.artikel == "" || this.config.dataset.artikel == "0"){
            await this.loadProducts(width, height).then(
                (data:any) => {
                    products = data.data;
                }
            );

            if(!products){
                console.error("Couldn't load products for current size from API");
                return false;
            }
        }

        //Buil API Data
        //Generate canvas data
        let designData = this.generateDesignData();
        //MOVE
        let id = this.config.shopInterface.buyButton.buttonID;
        if(id){
            let addToCartButton = document.getElementById(id) as HTMLButtonElement;
            if(addToCartButton){
                addToCartButton.classList.remove(this.config.shopInterface.buyButton.buttonRemoveClass);
                addToCartButton.classList.add(this.config.shopInterface.buyButton.buttonAddClass);
                addToCartButton.disabled = false;
                let text = addToCartButton.dataset.text;
                if(text){
                    addToCartButton.innerHTML = text;
                }
                addToCartButton.scrollIntoView({behavior: "smooth", block: "center"});
            }
        }

        let renderMode = RenderMode.Production;
        if(this.MCI){
            renderMode = RenderMode.ProductionMCI;
        }

        let imageData = this.getImageData(renderMode);
        if(!imageData){
            console.error('Couldnt generate image data');
            return false;
        }

        let textCount = 0;
        let imageCount = 0;
        for(let ele of this.elements){
            if(ele instanceof DesignerText && !(ele instanceof DesignerDate)){
                textCount++;
            }
            if(ele instanceof DesignerImage){
                imageCount++;
            }
        }

        let count = {
            'textCount': textCount,
            'imageCount': imageCount
        }

        let countString = JSON.stringify(count);

        let bonusParams = {
            data: JSON.stringify(designData),
            newDesigner: true,
            MCI: this.MCI,
            userID: this.config.userID,
            pixelToMM: this.config.pixelToMMUpload,
            imageData: imageData,
            count: countString,
        };
        
        //send API request for finished design
        let _this = this;
        this.API.request("sendconfig", bonusParams).then(
            (data:any) => {
                if(!data){
                    console.error("Empty Server Response");
                    return false;
                }
                if(data.message == "Die Daten wurden erfolgreich uebermittelt!"){
                    if(products){
                        this.showProducts(products);
                    }else{
                        //this.showMessage("success", `<strong>Gestaltung abgeschlossen!</strong> Sie können das Produkt jetzt mit Ihrer Gestaltung in den Warenkorb legen.`);

                        let dialog = _this.dialogInterface.addDialog("Gestaltung abgeschlossen!", "Sie können das Produkt jetzt mit Ihrer Gestaltung in den Warenkorb legen.", "Fortfahren", "");
                        dialog.show();

                        let e = new Event("change");
                        let element = document.getElementById('product-pcs');
                        if(element){
                            element.dispatchEvent(e);
                        }
                    }
                    return true;
                }else{
                    return false;
                }
            }
        ,(error) => {
            console.error(error);
            return false;
        });
    }

    //Update price on the page
    async updatePrice(){
        let bounds = this.calcBounds(this.canvas);
        let w = Math.ceil(bounds.w/this.config.pixelToMM);
        let h = Math.ceil(bounds.h/this.config.pixelToMM);

        let lines = this.textCount(false);
        await this.API.requestPrice(w, h, this.config.typ, lines).then(
            (data:any) => {
                if(!data){
                    return false;
                }
                //API nested it twice
                data = data.data;
                if(!data){
                    return false;
                }

                //Set prices
                if(data.vk && data.vk != "0" && data.vk != "0,00" && data.vk != "0.00"){
                    let VK_ID = this.config.shopInterface.priceInfo.VK_ID;
                    if(VK_ID){
                        let vkDIV = document.querySelector(VK_ID) as HTMLElement;
                        if(vkDIV){
                            let str = data.vk.replace(".", ",");
                            str += this.config.shopInterface.priceInfo.appendString;
                            vkDIV.innerHTML = str;
                        }
                    }
                }
                
                if(data.ek && data.vk != "0" && data.vk != "0,00" && data.vk != "0.00"){
                    let EK_ID = this.config.shopInterface.priceInfo.EK_ID;
                    if(EK_ID){
                        let ekDIV = document.querySelector(EK_ID) as HTMLElement;
                        if(ekDIV){
                            let str = data.ek.replace(".", ",");
                            str += this.config.shopInterface.priceInfo.appendString;
                            ekDIV.innerHTML = str;
                        }
                    }
                }

                if(data.vk_netto && data.vk_netto > 0 && data.vk_netto != "0,0" && data.vk_netto != "0.0"){
                    let VK_NETTO_ID = this.config.shopInterface.priceInfo.VK_NETTO_ID;
                    if(VK_NETTO_ID){
                        let vk_nettoDIV = document.querySelector(VK_NETTO_ID) as HTMLElement;
                        if(vk_nettoDIV){
                            let str = data.vk_netto.toString().replace(".", ",");
                            str += this.config.shopInterface.priceInfo.appendString;
                            vk_nettoDIV.innerHTML = str;
                        }
                    }
                }

                if(data.vcode){
                    let VCode_ID = this.config.shopInterface.priceInfo.VCode_ID;
                    if(VCode_ID){
                        let VCodeDIV = document.querySelector(VCode_ID) as HTMLElement;
                        if(VCodeDIV){
                            VCodeDIV.innerHTML = data.vcode;
                        }
                    }
                }
            }
        ,() => {
            return false;
        });
    }

    //Load possible prodcuts for current size
    loadProducts(width: number, height: number): any{
        let _this = this;
        return new Promise(function (resolve, reject) {
            let bonusParams:any = {
                width: width,
                height: height
            };

            if(_this.API.knr == ""){
                bonusParams.knr = 0;
            }
            
            _this.API.request("loadproducts", bonusParams).then(
                (data:any) => {
                    if(!data){
                        reject(false);
                    }
                    //data is object with properties, not array!
                    resolve(data);
                }
            ,(error) => {
                reject(error);
            });
            
        });
    }

    //Show products that fit the size
    showProducts(products: {article1:any, article2:any}){
        
        /*
            "artikel_nr": "HOLZSTEMPEL",
            "name": "Holzstempel",
            "bild": "https:\/\/www.stempelcloud24.com\/media\/artikel\/small\/Holz.jpg",
            "url": "https:\/\/www.stempel-online.com\/artikel\/holzstempel.html",
            "price": "10,46",
            "width": 35,
            "height": 18
        */
        let productsDIV = document.createElement("div");
        productsDIV.classList.add("product_list_overlay");
        productsDIV.innerHTML = this.HTMLData.products;

        let designerEditor = document.getElementById("designer_editor");
        if(!designerEditor){
            return false;
        }

        /*
        let children = designerEditor.children;
        let totalWidth = 0;
        let totalHeight = 0;

        for (var i = 0; i < children.length; i++) {
            let element = children[i] as HTMLElement;
            totalWidth += element.offsetWidth;
        }*/

        //let left = designerEditor.children[0].getBoundingClientRect().left;
        //productsDIV.style.left = left+"px";
        //productsDIV.style.width = totalWidth+"px";
        //productsDIV.style.height = designerEditor.clientHeight+"px";
        

        designerEditor.appendChild(productsDIV);
        let exactDIV = document.getElementById("products_exact_size");
        let largerDIV = document.getElementById("products_larger_size");

        //Exact fit
        for(let key in products.article1){
            let product = products.article1[key] as Product;
            let productCard = this.generateProductCard(product);
            exactDIV?.appendChild(productCard);
        }

        //Larger
        for(let key in products.article2){
            let product = products.article2[key] as Product;
            let productCard = this.generateProductCard(product);
            largerDIV?.appendChild(productCard);
        }

        let closeButton = document.getElementById("product_close");
        if(closeButton){
            closeButton.addEventListener("click", () => {
                productsDIV.remove();
            });
        }
        
    }

    //Product box for product list
    generateProductCard(product: Product){
        let productDIV = document.createElement("div");
        productDIV.classList.add("product_card");
        productDIV.addEventListener("click", () => {
            window.location.href = product.url;
        });
        productDIV.innerHTML = `<div> 
            <img class="product_thumbnail" src="${product.bild}">
        </div>
        <div>
            <div class="product_title">${product.name}</div>
            <div> Größe: ${product.width} x ${product.height} mm</div>
            <div> Preis: ${product.price}€</div>
        </div>`;
        return productDIV;
    }

    //Generate JSON save data
    generateSaveData(){
        let data = new SaveData(this);
        return data.stringify();
    }

    //Generate design data for API
    generateDesignData(){
        let bounds = this.calcBounds(this.canvas);
        let width = Math.ceil(bounds.w/this.config.pixelToMM);
        let height = Math.ceil(bounds.h/this.config.pixelToMM);

        let products:any = null;
        if(!this.config.dataset.artikel || this.config.dataset.artikel == "" || this.config.dataset.artikel == "0"){
            this.loadProducts(width, height).then(
                (data:any) => {
                    products = data.data;

                    if(!products){
                        console.error("Couldn't load products for current size from API");
                        return false;
                    }
                }
            );
        }

        let data = new DesignData(this.config.dataset.prototyp,
            this.config.dataset.canvas, 
            this.generateSaveData(), 
            this.config.dataset.tid, 
            this.config.dataset.kdnr, 
            this.config.dataset.knr,
            this.config.dataset.mode,
            this.config.typ,
            this.inkpadColor,
            width, 
            height,
            products);

        return data;
    }

    //Get user code from local storage (or set it to init code when not there)
    getUserCode(initUserCode: string): string{
        let localCode = localStorage.getItem("designer2021_userCode");
        if(localCode){
            return localCode;
        }else{
            localStorage.setItem("designer2021_userCode", initUserCode);
            return initUserCode;
        }
        
    }

    //Count of text elements present
    textCount(countEmpty = true){
        let count = 0;
        for(let element of this.elements){
            if(element instanceof DesignerText && !(element instanceof DesignerDate)){
                if(countEmpty){
                    count ++;
                }else{
                    if(element.text != "" && element.text.length > 0){
                        count ++;
                    }
                }
            }
        }
        return count;
    }

    //Maximum text elements reached?
    textMaximum(){
        if( this.textCount() >= this.config.init.text.maxRows){
            return true;
        }
        return false;
    }

    //Minimum text elements reached?
    textMinimum(){
        if( this.textCount() <= this.config.init.text.minRows){
            return true;
        }
        return false;
    }

    //Get last text element
    getLastText(): DesignerText | null{
        let text = null;
        for(let element of this.elements){
            if(element instanceof DesignerText && !(element instanceof DesignerDate)){
                text = element;
            }
        }
        return text;
    }

    //Remove all elments currently added
    clearElements(history = true, clearAll = false){
        //CLEAR ELEMENTS

        //Dont clear date when not specifically wanting to
        if(clearAll == false){
            /*
            for(let i = 0; i < this.elements.length; i++){
                let element = this.elements[i];
                if(element instanceof DesignerDate){
                    continue;
                }
                this.elements.splice(i,1);
            }*/

            this.elements = this.elements.filter(item => (item instanceof DesignerDate && !item.blockedActions.includes("remove") ));
        }else{
            this.elements = [];
        }
        this.update(true, history);
    }

    //Upload custom image
    uploadImage(file: File) {
        let reader = new FileReader();
        let _this = this;

        reader.onload = function(event){
            if(event.target?.result){
                let bonusParams = {
                    tid: _this.config.dataset.tid,
                    typ: _this.config.typ
                }
                _this.API.imageRequest("sendimage", bonusParams, file).then(
                    (response:SendImageResponse | SendImageError) => {
                        if(response.hasOwnProperty('error')){
                            let error = response as SendImageError;
                            _this.showMessage("error", error.message + "<br><br>" + error.error);
                            return false;
                        }
                        response = response as SendImageResponse;
                        _this.showMessage("success", response.info);
        
                        let center = _this.boundsCenter();

                        //Scale image
                        let area = _this.editAreaSize();

                        let w = response.width * _this.config.pixelToMM;
                        let h = response.height * _this.config.pixelToMM;

                        //Calculte ratio
                        let ratioW = w / area.w;
                        let ratioH = h / area.h;

                        //Always use highest ratio for scaling
                        //Aim for 1/3 (0.33) of the edit area when spawning image
                        if(ratioW > ratioH){
                            let scale = ratioW / 0.33;
                            w /= scale;
                            h /= scale;
                        }else{ 
                            let scale = ratioH / 0.33;
                            w /= scale;
                            h /= scale;
                        }

                        let image = new DesignerImage(_this, center.x, center.y, response.path, false, "#000000", w, h);
                        image.center();
                        image.addElement();
                    }
                ,(error) => {
                    _this.showMessage("error", error);
                    return false;
                });
            }
        }
        reader.readAsBinaryString(file);
    }

    async uploadImageVK(file: File){
        let reader = new FileReader();
        let _this = this;

        reader.onload = function(event){
            if(event.target?.result){
                let bonusParams = {
                    tid: _this.config.dataset.tid
                }
                _this.API.imageRequest("uploadVKImage", bonusParams, file).then(
                    (response:SendImageVKResponse) => {
                        if(response.error != null){
                            _this.showMessage("error", response.error);
                            return false;
                        }
                        if(response.savedResult){
                            _this.showMessage("success", "Bild für digitale Visitenkarte wurde erfolgreich hochgeladen ✅");
                        }
                    }
                ,(error) => {
                    _this.showMessage("error", error);
                    return false;
                });
            }
        }
        reader.readAsBinaryString(file);
    }

    //upload custom image that covers the entire area - a whole design
    async uploadImageFull(file: File, width: number){
        let reader = new FileReader();
        let _this = this;
        let label = document.getElementById("upload_full_label");
        label?.classList.add("uploading");
        reader.onload = function(event){
            if(event.target?.result){

                let bonusParams = {
                    tid: _this.config.dataset.tid,
                    typ: _this.config.typ,
                    width: width
                }

                _this.API.imageRequest("sendfile", bonusParams, file).then(
                    (response:SendFileResponse | SendImageError) => {
                        if(response.hasOwnProperty('error')){
                            let error = response as SendImageError;
                            _this.showMessage("error", error.message + "<br><br>" + error.error);
                            return false;
                        }
                        response = response as SendFileResponse;
                        label?.classList.remove("uploading");
                        //showMessage("success", response.info);

                        response.w = Math.floor(response.width_mm * _this.config.pixelToMM);
                        response.h = Math.floor(response.height_mm * _this.config.pixelToMM);
                        response.needsScaling = false;


                        if(response.height_mm > _this.config.maxHeightMM){
                            let headline = 'Gestaltung passt nicht in den Stempel!';
                            let message = `
                            <div>
                                Passen sie die Gestaltung an oder bestätigen sie das Verkleinern.
                            </div>
                            <div>
                                Höhe Gestaltung: `+Math.ceil(response.height_mm)+`mm <br>
                                Höhe Stempel: `+_this.config.maxHeightMM + `mm
                            </div>
                            <div>
                                Soll die Gestaltung auf die passende Größe verkleinert werden?
                            </div>`;

                            response.needsScaling = true;
                            let dialog = _this.dialogInterface.addDialog(headline, message, "Ja", "Abbrechen");
                            return dialog.show(response);
                        }
                        return new Promise<SendFileResponse | SendImageError>( (resolve) => {
                            resolve(response);
                        });
                    },
                    (error) => {
                        _this.showMessage("error", error);
                    }
                ).then( 
                    //Ja
                    (response) => {
                        if(response.needsScaling){
                            let errorMargin = 1;
                            let ratioW = response.height_mm / (_this.config.maxHeightMM-errorMargin);
                            let ratioH = response.width_mm / (_this.config.maxWidthMM-errorMargin);
                            let scaleFactor = Math.max(ratioW, ratioH);

                            response.h /= scaleFactor;
                            response.w /= scaleFactor;
                        }
                        return response;
                    }
                ).then(
                    //After additional scaling
                    (response) => {
                        let center = _this.editAreaCenter();

                        _this.clearElements(true, false);
                        _this.border.active = false;

                        let area = _this.editAreaSize();

                        response.w = Utility.clamp(response.w, 10, area.w-1);
                        response.h = Utility.clamp(response.h, 10, area.h-1);

                        let image = new DesignerImage(_this, center.x, center.y, response.path, false, "#000000" ,response.w, response.h);
                        image.makeFilling();
                        //image.center();
                        image.addElement();

                        if(_this.patternController.dateIsSet()){
                            _this.showMessage("warning", "Hinweis: Überprüfen Sie, dass der Datumsauschnitt keinen Inhalt ihrer Gestaltung verdeckt.");
                        }
                    }
                );
            }
        }
        reader.readAsBinaryString(file);
    }

    //Is there currently an image that is filling?
    hasFillingImage(){
        for(let element of this.elements){
            if(element instanceof DesignerImage && element.filling){
                return element;
            }
        }
        return false;
    }

    //Calculate the pixel scaling that can fit into the maximum width
    calculateCanvasDimensions(targetWidth = this.config.scalingTargetWidth){
        let pixelToMM = Math.floor( ( targetWidth-this.config.rulerSpaceEnd-this.config.rulerX ) / this.config.maxWidthMM );
        return pixelToMM;
    }

    //Set size of the canvas
    setCanvasDimensions(applyAutomaticScaling = true, targetWidth = this.config.scalingTargetWidth){
        //Calculate pixel scaling based on max width
        if(applyAutomaticScaling && this.config.automaticScaling){
            this.config.pixelToMM = this.calculateCanvasDimensions(targetWidth);
        }
        //max sizes
        this.canvas.width = (this.config.maxWidthMM * this.config.pixelToMM)+this.config.rulerSpaceEnd+this.config.rulerX;
        this.canvas.height = (this.config.maxHeightMM * this.config.pixelToMM)+this.config.rulerSpaceEnd+this.config.rulerY;

        this.ctx.translate(0.5, 0.5);
    }

    //Check if the canvas fits into the current designer width, else downscale
    checkCanvasFits(){
        let designerEditor = document.getElementById("designer_editor");
        if(!designerEditor){
            return;
        }
        let designerWidth = designerEditor?.clientWidth;
        let viewSize = window.innerWidth;
        //Only apply to mobile breakpoint
        if(viewSize < 900){
            let canvasWidth = this.canvas.width;
            let uiWidth = this.config.rulerX;
            let designerWidthTrue = designerWidth - 20 - uiWidth;
            
            if(designerWidthTrue < canvasWidth || canvasWidth < this.config.scalingTargetWidth){

                //Calculate new scaling factor that fits, with some room for error
                let newScale = Math.floor( designerWidthTrue / this.config.widthMM);
                
                this.changePixelScaling(newScale, true);
                this.setCanvasDimensions(false);
            }
        }
    }
    
    /**
     * SOURCE: https://opensourcehacker.com/2011/12/01/calculate-aspect-ratio-conserving-resize-for-images-in-javascript/
     * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
     * images to fit into a certain area.
     *
     * @param {Number} srcWidth width of source image
     * @param {Number} srcHeight height of source image
     * @param {Number} maxWidth maximum available width
     * @param {Number} maxHeight maximum available height
     * @return {Object} { width, height }
     */
    calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {

        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { 
            width: srcWidth*ratio,
            height: srcHeight*ratio ,
            deltaWidth: (srcWidth*ratio) - srcWidth,
            deltaHeight: (srcHeight*ratio) - srcHeight
        };
    }

    //Calculate how much of the available area is actually used
    checkUsedSpace(){
        if(this.config.dataset.artikel != "" && this.config.dataset.artikel != null){
            let area = this.editAreaSize();
            let bounds = this.calcBounds();
            let areaUsed = (bounds.w * bounds.h) / (area.w * area.h);

            if(areaUsed < 0.8){
                let newScale = this.calculateFill(true);
                let scaleChange = newScale - this.scaling;

                if(scaleChange != 0){
                    return false;
                }
            }
        }
        return true;
    }

    //Check if the pattern is entirely static
    isStatic(){
        for(let element of this.elements){
            if(!element.static){
                return false;
            }
        }
        return true;
    }

    //Update everything if something changed
    update(updateText = false, history = true, localSave = true){
        if(updateText){
           this.tabInterface.renderTextTab();
        }
        this.updateElementPoisitons();
        this.setElementErrors();
        this.setUIValues();
        //Add to undo history
        if(history){
            this.startUndoTimer(localSave);
        }
        if(this.activeElementTools){
            let element = this.activeElementTools.element;
            let offset = Utility.relativePosition(this.canvas, this.canvasDIV);
            let elementBounds = element.getBounds();
            //this.activeElementTools.move(elementBounds.x+(elementBounds.w/2) + offset.x, elementBounds.y + offset.y);
        }
    }

    updateElementPoisitons(){
        //Check for elements outside view
        for(let element of this.elements){
            if(element instanceof DesignerDate){
                continue;
            }
            if(element instanceof DesignerImage){
                //Adjust filling elements
                element.fillUpdate();
            }
            element.checkBounds(false);
        }
    }

    //Check elements for errors
    setElementErrors(){
        for(let element of this.elements){
            element.error = false;
        }

        //Check out of bounds
        let boundCollisions = this.checkBoundCollisions();
        if(boundCollisions){
            for(let element of boundCollisions){
                element.setError(ElementError.OutOutBounds);
            }
        }

        //Check date collision
        let dateCollisions = this.checkDateCollision();
        if(dateCollisions){
            for(let element of dateCollisions){
                element.setError(ElementError.DateCollision);
            }
        }

        //Check text collision
        /*
        let textCollisions = this.checkTextCollision();
        if(textCollisions){
            for(let element of textCollisions){
                element.setError(ElementError.TextCollision);
            }
        }*/

        //MCI
        if(this.MCI == true){
            //Group elements of same colour if possible
            this.generateMCIGroups();

            //Check minimum sizes and distance based on config settings
            let MCIMinimums = this.checkMCIMinimums();
            let MCISpacing = this.checkMCISpacing();
            if(MCIMinimums){
                for(let element of MCIMinimums){
                    element.setError(ElementError.MCIMinimum);
                }
            }
           
            if(MCISpacing){
                //Reset error entries
                for(let error of MCISpacing){
                    error.element.resetMCISpacingErrors();
                }
                //Set error entries
                for(let error of MCISpacing){
                    error.element.setMCISpacingError(error);
                }
            }
        }
    }

    

    //Start 1sec countdown to save changes
    startUndoTimer(localSave = true){
        if(this.undoTimer !== undefined){
            clearTimeout(this.undoTimer);
        }
        let _this = this;
        this.undoTimer = window.setTimeout(() => {
            _this.addHistoryPoint(localSave);
        }, 1000);
    }

    //Delete element
    deleteElement(element: DesignerElement){
        const index = this.elements.indexOf(element, 0);
        if (index > -1) {
            this.elements.splice(index, 1);
            this.centerContent();
            this.update(true, true);
        }
    }


    //POPUP

    //Close popup if there is one open
    closeActivePopups(){
        this.closeActiveElementTools();
        this.closeActiveHover();
    }

    //Close popup if there is one open
    closeActiveElementTools(){
        if(this.activeElementTools){
            this.activeElementTools.close()
            this.activeElementTools = null;
        }
    }

    openElementToolsOnElement(element: DesignerElement){
        let elementBounds = element.getBounds();
        let x = elementBounds.x+(elementBounds.w/2);
        let y = 16;
        return this.openElementTools(x,y, element);
    }

    //Open Popup-Menu
    openElementTools(x:number, y:number, element: DesignerElement){
        this.closeActiveElementTools();
        x = Utility.clamp(x, 0, this.canvas.width);
        y = Utility.clamp(y, 0, this.canvas.height);

        let offset = Utility.relativePosition(this.canvas, this.canvasDIV);

        x += offset.x;
        y += offset.y;

        this.activeElementTools = new ElementTools(this, element, x, y, -16);
        if(this.activeElementTools.getUsableMenuElements() == 0){
            this.closeActiveElementTools();
        }
        return this.activeElementTools;
    }

    //Close popup if there is one open
    closeActiveHover(){
        if(this.activeHover){
            this.activeHover.close()
            this.activeHover = null;
        }
    }

    //Show hover
    openHover(element: DesignerElement, text: string){
        if(this.activeHover && this.activeHover.element != element){
            this.closeActiveHover();
        }
        if(this.activeHover && this.activeHover.element == element){
            return;
        }
        
        let x = element.x+(element.w/2);
        let y = element.y;

        x = Utility.clamp(x, 0, this.canvas.width);
        y = Utility.clamp(y - 50, 0, this.canvas.height);
        
        this.activeHover = new Hover(this,element, x, y, 0, text);
    }

    //POPUP END


    //Check if anything is in the date bounds
    checkDateCollision(): DesignerElement[] | false{
        let date = null;
        //Find date element
        for(let element of this.elements){
            if(element instanceof DesignerDate){
                date = element;
                break;
            }
        }

        if(!date){
            return false;
        }

        let minX = date.x - date.rectW/2;
        let minY = date.y - date.rectH/2;
        let maxX = minX+date.rectW;
        let maxY = minY+date.rectH;

        let collisions = [];

        for(let element of this.elements){
            if(element instanceof DesignerDate){
                continue;
            }
            let elementBounds = element.getBounds();
            //Collision
            if( elementBounds.x < maxX && elementBounds.x+elementBounds.w > minX &&
                elementBounds.y < maxY && elementBounds.y+elementBounds.h > minY ){
                    collisions.push(element);
            }
        }
        if(collisions.length > 0){
            return collisions;
        }
        return false;
    }

    //Check for overlapping texts
    checkTextCollision(): DesignerText[] | false{
        let collisions = [];

        let paddingMargin = 1;
        //Compare every text with every text
        for(let element of this.elements){
            if(element instanceof DesignerText && !(element instanceof DesignerDate)){
                //Ignore elements at odd angles - not able to calculate accurate collisions
                if(element.rotation % 90 != 0){
                    continue;
                }

                let elementBounds = element.getBounds();
                let minX = elementBounds.x;
                let minY = elementBounds.y + paddingMargin;
                let maxX = elementBounds.x + elementBounds.w;
                let maxY = (elementBounds.y + elementBounds.h) - paddingMargin;

                for(let otherElement of this.elements){
                    if(element == otherElement){
                        continue;
                    }
                    if(otherElement instanceof DesignerText && !(otherElement instanceof DesignerDate)){
                        if(otherElement.rotation % 90 != 0){
                            continue;
                        }
                        let otherElementBounds = otherElement.getBounds();
                        //Collision
                        if( otherElementBounds.x < maxX && otherElementBounds.x+otherElementBounds.w > minX &&
                            otherElementBounds.y < maxY && otherElementBounds.y+otherElementBounds.h > minY ){

                            collisions.push(element);
                        }
                    }
                }
            }
        }
        
        if(collisions.length > 0){
            return collisions;
        }
        return false;
    } 

    //Check if element collides with area bounds
    checkBoundCollisions(){
        let collisions = [];
        let editArea = this.editAreaSize();

        for(let element of this.elements){
            if(element instanceof DesignerDate){
                continue;
            }
            if(element.static){
                continue;
            }
            let elemenentBounds = element.getBounds();
            //Collision
            if( elemenentBounds.x < editArea.minX || elemenentBounds.x+elemenentBounds.w > editArea.maxX ||
                elemenentBounds.y < editArea.minY || elemenentBounds.y+elemenentBounds.h > editArea.maxY){
                    collisions.push(element);
            }
        }
        if(collisions.length > 0){
            return collisions;
        }
        return false;
    }

    //Check if element has MCI minimum sizes
    checkMCIMinimums(): false | DesignerElement[]{
        let minWidth = this.config.init.mci.minWidth * this.config.pixelToMM;
        let minHeight = this.config.init.mci.minHeight * this.config.pixelToMM;
        let spacingPixel = this.config.init.mci.spaceBetween * this.config.pixelToMM;
        let found = [];
        for(let element of this.elements){
            if(element instanceof DesignerDate || element.static){
                continue;
            }
            if(this.border.active && this.border.color == element.color){
                continue;
            }
            let elemenentBounds = element.getBounds();
            if(element.MCIGroup.length > 0){
                elemenentBounds = element.getMCIGroupBounds();
            }

            if(element instanceof DesignerRect){
                //4mm + (2mm*2)
                let lineMininum = minWidth + (spacingPixel*2);
                if( element.getLength() < lineMininum){
                    found.push(element);
                }
                continue;
            }

            let w = elemenentBounds.w;
            let h = elemenentBounds.h;

            if(element instanceof DesignerText){
                h = Math.max(h, Utility.pt2px( element.size, this.config.pixelToMM) );
            }

            if(w < minWidth || h < minHeight){
                found.push(element);
            }
        }
        if(found.length > 0){
            return found;
        }
        return false;
    }

    //Check if the space between elements is at least the MCI minimum
    checkMCISpacing(): false | MCISpacingError[]{
        let area = this.calcBounds();
        let found = [];
        let spacingPixel = this.config.init.mci.spaceBetween * this.config.pixelToMM;
        for(let element of this.elements){
            let spacingX = spacingPixel;
            let spacingY = spacingX;
            if(element instanceof DesignerDate || element.static){
                continue;
            }
            let elemenentBounds = element.getBounds();

            //Rects/Lines can be smaller, but need extra space
            if(element instanceof DesignerRect){
                let lineMininum = this.config.init.mci.minHeight * this.config.pixelToMM + spacingPixel;
                if(element.MCIGroup.length == 0){
                    if(element.orientation == "horizontal"){
                        spacingY = Math.max(lineMininum - element.getWidth(), spacingY);
                    }
                    if(element.orientation == "vertical"){
                        spacingX = Math.max(lineMininum - element.getWidth(), spacingX);
                    }
                }
            }

            let relativeX = elemenentBounds.x - area.minX;
            let relativeY = elemenentBounds.y - area.minY;

            //Border
            if(this.border.active && this.border.color != element.color){
                //Vertical
                if( relativeY < spacingY ) {
                    let distY = relativeY;
                    found.push({
                        element: element,
                        otherElement: null,
                        distX: 0,
                        distY: distY
                    });
                }
                if(relativeY + elemenentBounds.h > area.h - spacingY){
                    let distY = (relativeY + elemenentBounds.h) - (area.h);
                    found.push({
                        element: element,
                        otherElement: null,
                        distX: 0,
                        distY: distY
                    });
                }
                //Horizontal
                if(relativeX < spacingX){
                    let distX = relativeX;
                    found.push({
                        element: element,
                        otherElement: null,
                        distX: distX,
                        distY: 0
                    });
                }
                if(relativeX + elemenentBounds.w > area.w - spacingX){
                    let distX = (relativeX + elemenentBounds.w) - (area.w);
                    found.push({
                        element: element,
                        otherElement: null,
                        distX: distX,
                        distY: 0
                    });
                }
            }

            for(let otherElement of this.elements){
                let spacingX = spacingPixel;
                let spacingY = spacingX;

                if(element instanceof DesignerRect){
                    let lineMininum = this.config.init.mci.minHeight * this.config.pixelToMM + spacingPixel;
                    if(element.MCIGroup.length == 0){
                        if(element.orientation == "horizontal"){
                            spacingY = Math.max(lineMininum - element.getWidth(), spacingY);
                        }
                        if(element.orientation == "vertical"){
                            spacingX = Math.max(lineMininum - element.getWidth(), spacingX);
                        }
                    }
                }

                if(otherElement instanceof DesignerDate || otherElement.static || otherElement == element){
                    continue;
                }
                //Only check if different color
                if(otherElement.color == element.color){
                    continue;
                }

                let otherElementBounds = otherElement.getBounds();

                //Rects/Lines can be smaller, but need extra space
                if(otherElement instanceof DesignerRect){
                    let lineMininum = this.config.init.mci.minHeight * this.config.pixelToMM + spacingPixel;
                    if(otherElement.MCIGroup.length == 0){
                        if(otherElement.orientation == "horizontal"){
                            spacingY = Math.max(lineMininum - otherElement.getWidth(), spacingY);
                        }
                        if(otherElement.orientation == "vertical"){
                            spacingX = Math.max(lineMininum - otherElement.getWidth(), spacingX);
                        }
                    }
                }

                let deltaX = Math.abs(element.x - (otherElement.x));
                let deltaY = Math.abs(element.y - (otherElement.y));

                let distX = deltaX - (elemenentBounds.w/2 + otherElementBounds.w/2);
                let distY = deltaY - (elemenentBounds.h/2 + otherElementBounds.h/2);

                distX = Math.max(distX, 0);
                distY = Math.max(distY, 0);
                
                if(distX < spacingX && distY < spacingY){
                    found.push( {
                        element: element,
                        otherElement: otherElement,
                        distX: distX,
                        distY: distY
                    });
                }
            }
        }
        if(found.length > 0){
            return found;
        }
        return false;
    }

    //Generate groups based on element clours and blocking
    generateMCIGroups(){
        //Calculate groups - check if any part of another element is within the same row or column 
        for(let element of this.elements){
            if(element instanceof DesignerDate){
                continue;
            }

            let sortingGroupUp:DesignerElement[] = [];
            let sortingGroupDown:DesignerElement[] = [];
            let sortingGroupRight:DesignerElement[] = [];
            let sortingGroupLeft:DesignerElement[] = [];
            
            let bounds = element.getBounds();
            for(let otherElement of this.elements){
                if(otherElement == element){
                    continue;
                }
                let otherBounds = otherElement.getBounds();
                //Vertical
                if( bounds.x < otherBounds.x+otherBounds.w && bounds.x+bounds.w > otherBounds.x){
                    //Up
                    if(otherElement.y < element.y){
                        sortingGroupUp.push(otherElement);
                    }
                    //Down
                    if(otherElement.y > element.y){
                        sortingGroupDown.push(otherElement);
                    }
                }

                //Horizontal
                if( bounds.y < otherBounds.y+otherBounds.h && bounds.y+bounds.h > otherBounds.y){
                    //Left
                    if(otherElement.x < element.x){
                        sortingGroupLeft.push(otherElement);
                    }
                    //Right
                    if(otherElement.x > element.x){
                        sortingGroupRight.push(otherElement);
                    }
                }
            }

            //Order vertical group by Y position
            sortingGroupUp.sort((a,b) => {
                return b.y - a.y;
            });
            sortingGroupDown.sort((a,b) => {
                return a.y - b.y;
            });

            //Order horizontal group by X position
            sortingGroupLeft.sort((a,b) => {
                return b.x - a.x;
            });
            sortingGroupRight.sort((a,b) => {
                return a.x - b.x;
            });

            let group:DesignerElement[] = [];

            group = this.sortMCIGroup(sortingGroupUp, group, element);
            group = this.sortMCIGroup(sortingGroupDown, group, element);
            group = this.sortMCIGroup(sortingGroupLeft, group, element);
            group = this.sortMCIGroup(sortingGroupRight, group, element);

            element.MCIGroup = group;
        }

        //Connect groups
        for(let element of this.elements){
            if(element instanceof DesignerDate){
                continue;
            }
            for(let otherElement of element.MCIGroup){
                this.linkMCIGroup(element, otherElement);
            }
        }
    }

    //Recusive linking of groups
    linkMCIGroup(element: DesignerElement, otherElement: DesignerElement){
        if(otherElement instanceof DesignerDate){
            return;
        }
        //Backpropagation of link
        if(!otherElement.MCIGroup.includes(element)){
            otherElement.MCIGroup.push(element);
        }

        if(otherElement.MCIGroup.length > 0){
            for(let groupElement of otherElement.MCIGroup){
                //Loop prevention
                if(!element.MCIGroup.includes(groupElement)){
                    element.MCIGroup.push(groupElement);
                    this.linkMCIGroup(element, groupElement);
                }
            }
        }
    }

    sortMCIGroup(sortingGroup : DesignerElement[], targetGroup : DesignerElement[], element: DesignerElement){
        for(let otherElement of sortingGroup){
            if(otherElement.color != element.color || otherElement instanceof DesignerDate){
                break;
            }
            if(otherElement.color == element.color){
                if(!targetGroup.includes(otherElement)){
                    targetGroup.push(otherElement);
                }
            }
        }
        return targetGroup;
    }

    //Display element errors as messages above designer
    async displayErrorAlerts(askForFill = true){
        let returnVal = false;

        return new Promise( (resolve ) => {
            this.setElementErrors();
            for(let element of this.elements){
                if(element.error){
                    this.showErrorMessage(element.errorType);
                    returnVal = true;
                }
            }
            resolve(returnVal);

        }).then((returnVal) => {
            //Only ask for fill under 100mm
            let mmWidth = this.editAreaSize().w / this.config.pixelToMM;

            let fillRatio = this.calculateFill(true);

            //Ask for filling space - only for finish!
            if(askForFill && !returnVal && mmWidth < 100 && fillRatio >= 1 && !this.patternController.dateIsSet() && !this.checkUsedSpace() && !this.isStatic()){

                let headline = 'Gestaltungsbereich nicht vollständig genutzt!';

                let message = `
                <div>
                    Soll versucht werden die Gestaltung automatisch passend zu vergrößern?
                </div>`;

                let dialog = this.dialogInterface.addDialog(headline, message, "Ja", "Nein (Weiter)");
                return dialog.show(returnVal);
            }
            //Reject promise
            return new Promise( (resolve, reject) => {
                reject(returnVal);
            });

        }).then(
            //Ja
            (returnVal) => {
                this.scaleFill(true);
                return returnVal;
            },
            //Nein
            (returnVal) => {
                return returnVal;
            }
        );
    }

    //Set localData save for quick reloading
    setLocalSave(saveDataJSON: string){
        localStorage.setItem("designer2021_last_pattern", saveDataJSON);
    }

    //UNDO/REDO
    //Design changed -> save temp copy for undo
    addHistoryPoint(localSave = true){
        //Max 10 steps for undo
        if(this.historyUndo.length > this.config.historyLength){
            this.historyUndo.shift();
        }

        //Create new save point
        let newData = this.generateSaveData();
        let undoObject: UndoObject = {
            saveDataJSON: newData
        };
        this.historyUndo.push(undoObject);
        this.historyRedo = [];
        this.updateUndoRedoButtons();
        if(localSave){
            this.setLocalSave(newData);
        }

        if(this.config.shopInterface.priceInfo.realtimeUpdates){
            if(this.doPriceUpdate){
                if(this.config.typ == "Holzstempel" || this.config.typ == "Stempelplatte"){
                    this.updatePrice();
                }
            }
        }
    }

    //Undo last change
    undo(){
        this.historyUndo.pop();
        let shift = this.historyUndo[this.historyUndo.length-1];
        if(shift){
            //Create new save point for redo
            let newData = this.generateSaveData();
            let undoObject: UndoObject = {
                saveDataJSON: newData
            };
            this.historyRedo.push(undoObject);
            this.loadHistoryPoint(shift.saveDataJSON);
        }
        this.updateUndoRedoButtons();
        this.update(true,false);
    }

    //Redo last change
    redo(){
        let shift = this.historyRedo.pop();
        if(shift){
            //Create new save point for undo
            this.loadHistoryPoint(shift.saveDataJSON);
            let newData = this.generateSaveData();
            let undoObject: UndoObject = {
                saveDataJSON: newData
            };
            this.historyUndo.push(undoObject);
        }
        this.updateUndoRedoButtons();
        this.update(true,false);
    }

    //Update classes of buttons to inactive / active
    updateUndoRedoButtons(){
        let undo = document.getElementById("toolbar_undo");
        if(this.historyUndo.length < 2){
            undo?.classList.add("inactive");
        }else{
            undo?.classList.remove("inactive");
        }
        let redo = document.getElementById("toolbar_redo");
        if(this.historyRedo.length < 1){
            redo?.classList.add("inactive");
        }else{
            redo?.classList.remove("inactive");
        }
    }

    //UNDO/REDO END



    //Set UI elements (eg. slider) to current values
    setUIValues(){
        let bounds = this.calcBounds(this.canvas);
        let sizeX = Math.ceil(bounds.w/this.config.pixelToMM);
        let sizeY = Math.ceil(bounds.h/this.config.pixelToMM);

        document.getElementById('current_size_mm')!.innerHTML = sizeX + " x " + sizeY +"mm";
        document.getElementById('max_size_mm')!.innerHTML = this.config.maxWidthMM + " x " + this.config.maxHeightMM + "mm";

        let checkGrid = (document.getElementById('showgrid_checkbox') as HTMLInputElement);
        let snapGrid = (document.getElementById('snapgrid_checkbox') as HTMLInputElement);
        if(this.showGrid){
            checkGrid.checked = true;
        }else{
            checkGrid.checked = false;
        }
        if(this.snapToGrid){
            snapGrid.checked = true;
        }else{
            snapGrid.checked = false;
        }

        let buttons = document.getElementsByClassName("padColor");
        for(let button of buttons){
            button.classList.remove('active');
            if( (button as HTMLDivElement).dataset.color == this.inkpadColor){
                button.classList.add('active');
            }
        }

        //BORDER
        if(this.tabInterface.activeTab == "border" && this.border){
            let border_strength = document.getElementById('border_strength') as HTMLInputElement;
            if(border_strength){
                border_strength.value = (this.border.width).toString();
            }

            let border_padding = document.getElementById('border_padding') as HTMLInputElement;
            if(border_padding){
                border_padding.value = (this.border.padding).toString();
            }
        }

        //VK
        if(this.tabInterface.activeTab == "qr"){
            let form = document.getElementById('qr_vk_form') as HTMLFormElement;

            //Load cached values when tab has changed
            if(form){
                let vkElements = form.elements;
                for(let element of vkElements){
                    //Exclude file and hidden input
                    if(element instanceof HTMLInputElement && element.type != "file" && element.type != "hidden"){
                        //Check if property exists
                        let val = this.vkdata[element.name];
                        if(val){
                            element.value = val;
                        }
                    }
                }
            }

            let vk_link_div = document.getElementById('vk_links_div');

            let vk_link = document.getElementById('vk_link') as HTMLAnchorElement;
            if(vk_link){
                if(this.vkdata.url){
                    vk_link.href = this.vkdata.url;
                    if(vk_link_div){
                        vk_link_div.style.display = "flex";
                    }
                }
            }
            let vk_link_edit = document.getElementById('vk_link_edit') as HTMLAnchorElement;
            if(vk_link_edit){
                if(this.vkdata.urlEdit){
                    vk_link_edit.href = this.vkdata.urlEdit;
                    if(vk_link_div){
                        vk_link_div.style.display = "flex";
                    }
                }
            }
        }

        //ZOOM
        let zoomSlider = document.getElementById('zoom_slider') as HTMLInputElement;
        zoomSlider.value = this.config.pixelToMM.toString();
        let maxSize = this.calculateCanvasDimensions();
        zoomSlider.max = maxSize.toString();
        //Limit slider steps to a maximum
        zoomSlider.min = Math.max( Number.parseInt(zoomSlider.min), Math.floor(maxSize/2) ).toString();

        //SCALE
        let scaleSlider = document.getElementById('scale_slider') as HTMLInputElement;
        if(scaleSlider){
            scaleSlider.value = this.scaling.toString();
        }

        if(this.MCI && this.dateElement && this.dateElement.color){
            let coloring = document.querySelector(".mci_date_colorpicker button img");
            if(coloring){
                let img = coloring as HTMLImageElement;
                //Set bordor to current date color
                img.style.borderColor = this.dateElement.color;
            }
        }
        
        //Apply to all
        this.updateApplyToAllDropdown();
    }

    //Check if a QR code (with text target) already exists
    checkQRCodeExists(text: string = ""): null | DesignerQRCode{
        for(let element of this.elements){
            if(element instanceof DesignerQRCode){
                //No parameter, check for ANY qr code
                if(text == ""){
                    return element;
                }
                if(element.text == text){
                    return element;
                }
            }
        }
        return null;
    }

    updateApplyToAllDropdown(){
        let dropdown = document.getElementById('apply_to_all') as HTMLSelectElement;
        if(!dropdown){
            return;
        }
        //Trim to allowed
        for(let i = 0;i < dropdown.length;i ++){
            let option = dropdown.options[i];
            if(option.value == "fontsize"){
                if(this.config.init.text.changeSize != "true" || this.config.init.text.globalSize == "false" ){
                    option.remove();
                    i--;
                }
            }
            if(option.value == "font"){
                if(this.config.init.text.changeSize != "true" || this.config.init.text.globalFont == "false"){
                    option.remove();
                    i--;
                }
            }
            if(option.value == "align"){
                if(this.config.init.text.changeAlignment != "true" || this.config.init.text.globalAlgin == "false"){
                    option.remove();
                    i--;
                }
            }
            if(option.value == "text_special"){
                if(this.config.init.text.bold != "true" && this.config.init.text.underline != "true" && this.config.init.text.italic != "true"){
                    option.remove();
                    i--;
                }
            }
        }

        let existing = document.querySelectorAll(".designer_apply_to_all_input");
        for(let element of existing){
            element.remove();
        }

        //Remove bar when there are no options
        if(dropdown.options.length == 0){
            document.querySelectorAll(".apply_to_all_container")[0].remove();
            return;
        }

        let first: DesignerText|null = null;
        for(let element of this.elements){
            if(element instanceof DesignerText && !(element instanceof DesignerDate)){
                first = element;
                break;
            }
        }
        let defaultValue, inputField;
        switch(dropdown.value){
            case 'fontsize': 
                defaultValue = 0;
                if(first){
                    defaultValue = first.size;
                }
                inputField = this.tabInterface.generateFontSizeDropdown(this.config.fontIncrement, defaultValue);
                inputField.classList.add('designer_apply_to_all_input');
                dropdown.after(inputField);
            break;
            case 'font': 
                defaultValue = "";
                if(first){
                    defaultValue = first.font;
                }
                inputField = this.tabInterface.generateFontDropdown(defaultValue);
                inputField.classList.add('designer_apply_to_all_input');
                dropdown.after(inputField);
            break;
            case 'align': 
                defaultValue = null;
                if(first){
                    defaultValue = first.align;
                }
                inputField = this.tabInterface.generateAlignDropdown(defaultValue);
                inputField.classList.add('designer_apply_to_all_input');
                dropdown.after(inputField);
            break;
            case 'text_special': 
                defaultValue = "";
                if(first){
                    if(first.bold){
                        defaultValue = "bold";
                    }
                    if(first.italic){
                        defaultValue = "italic";
                    }
                    if(first.underline){
                        defaultValue = "underline";
                    }
                }
                inputField = this.tabInterface.generateSpecialDropdown(defaultValue as TextSpecials);
                inputField.classList.add('designer_apply_to_all_input');
                dropdown.after(inputField);
            break;
        }
    }


    addMCIDateColorPicker(){
        let date = this.dateElement;
        if(date){
            let menuButtonMCI = this.tabInterface.generateMCIButton(date.color);

            menuButtonMCI.addEventListener('click',  (evt) => {
                if (date) {
                    this.input.elementSelected = date;
                    let tools = this.openElementToolsOnElement(date);
                    
                    let toggle = this.activeElementTools?.findElement("mci_toggle_button") as PopupToggle;
                    toggle.toggleOn();
                }
            });

            let div = document.createElement('div');
            div.classList.add("mci_date_colorpicker")
            let label = document.createElement('div');
            label.innerText = "Datumsfarbe ändern: ";
            div.appendChild(label);
            div.appendChild(menuButtonMCI);

            let canvas_details_0 = document.querySelector(".canvas_details_0");
            if(canvas_details_0){
                canvas_details_0.appendChild(div);
            }
        }
    }
    

    //Generate available inkpad colors
    generateInkpadColors(colorObject: any){
        let pad = document.getElementById('inkpad_colors');
        if(pad){
            //let colors = ['black', 'red', 'lime', 'blue', 'purple'];
            //colors is numerated object, not array ...
            //Convert to array
            let colors:ColorObject[] = [];
            for(let key in colorObject){
                let val:ColorObject = colorObject[key];
                colors.push(val)
            }
            //If array empty, dont display
            let group = document.getElementById('inkpad_group');
            if(colors.length < 1){
                if(group){
                    console.log("No inkpad colors, removing menu");
                    group.remove();
                }
                return false;
            }

            for(let color of colors){
                if(color.name == "ZWEIFARBIG"){
                    this.twoTone = true;
                    this.setInkpadColor(color.hex);
                    if(group){
                        console.log("Zweifarbig, entferne Menüpunkt");
                        group.remove();
                    }
                    return false;
                }
                let colorButton = document.createElement('div');
                colorButton.classList.add('padColor');
                colorButton.style.backgroundColor = color.hex;
                colorButton.dataset.color = color.hex;
                colorButton.dataset.colorName = color.name;
                pad.appendChild(colorButton);

                let _this = this;
                colorButton.addEventListener('click', function(evt){
                    if(!evt.target){
                        return;
                    }
                    let target = (evt.target as HTMLDivElement);
                    _this.setInkpad(target);
                });

                if(this.config.init.pad == color.hex){
                    this.setInkpad(colorButton);
                }
            }
            
        }
    }

    //Set inkpad color
    setInkpad(target: HTMLDivElement){
        let color = target.dataset.color;
        if(color){
            let buttons = document.getElementsByClassName("padColor");
            for(let button of buttons){
                button.classList.remove('active');
            }
            (target as HTMLDivElement).classList.add('active');
            this.setInkpadColor(color);
        }
    }

    setInkpadColor(color: string){
        this.inkpadColor = color;
        for(let element of this.elements){
            element.setColor(color);
        }
    }

    //Get element by ID
    getElement(id: number){
        for(let element of this.elements){
            if(element.id == id){
                return element;
            }
        }
    }

    //Change Border
    setBorder(border: Border|null){
        if(border){
            this.border = {
                active: border.active,
                padding: border.padding,
                width: border.width,
                double: border.double,
                style: border.style,
                color: border.color ?? "#000000"
            };
        }else{
            this.border.active = false;
        }
    }


    //BOUNDS

    //Calculate bounds of stamp
    calcBounds(canvas: HTMLCanvasElement = this.canvas, upscale = false, applyMinimum = true, ignoreDate = false): Bounds{

        let center = this.editAreaCenter(false, upscale, canvas);
        let minX = center.x;
        let maxX = center.x;
        let minY = center.y;
        let maxY = center.y;

        //start with simple bounds of first elements, dont start at 0,0
        if( this.elements.length > 0){
            let ele0 = this.elements[0];
            if(ele0 instanceof DesignerDate){
                if(!ignoreDate){
                    //let boxPosition = ele0.boxPosition();
                    let eleBounds = ele0.getBounds();
                    minX = eleBounds.x;
                    maxX = eleBounds.x+eleBounds.w;
                    minY = eleBounds.y;
                    maxY = eleBounds.y+eleBounds.h;
                }
            }else{
                let eleBounds = ele0.getBounds();
                minX = eleBounds.x;
                maxX = eleBounds.x+eleBounds.w;
                minY = eleBounds.y;
                maxY = eleBounds.y+eleBounds.h;
            }
        }
        
        for(let element of this.elements){
            if(element instanceof DesignerDate){
                if(ignoreDate){
                    continue;
                }
                let eleBounds = element.getBounds();
                minX = Math.min(eleBounds.x,minX);
                maxX = Math.max(eleBounds.x+eleBounds.w,maxX);

                minY = Math.min(eleBounds.y,minY);
                maxY = Math.max(eleBounds.y+eleBounds.h,maxY);
            }else{
                let eleBounds = element.getBounds();

                minX = Math.min(eleBounds.x,minX);
                maxX = Math.max(eleBounds.x+eleBounds.w,maxX);
    
                minY = Math.min(eleBounds.y,minY);
                maxY = Math.max(eleBounds.y+eleBounds.h,maxY);
            }
        }

        let rulerX = this.config.rulerX;
        let rulerY = this.config.rulerY;
        let rulerSpaceEnd = this.config.rulerSpaceEnd;

        if(upscale){
            rulerX = 0;
            rulerY = 0;
            rulerSpaceEnd = 0;
        }

        let area = this.editAreaSize(false, upscale, canvas);

        //Apply maximum
        minX = Math.max(minX, area.minX);
        minY = Math.max(minY, area.minY);
        maxX = Math.min(maxX, area.maxX);
        maxY = Math.min(maxY, area.maxY);

        let borderBounds = {
            'minX': minX,
            'maxX': maxX,
            'minY': minY,
            'maxY': maxY
        }

        if(this.borderShows()){
            let spacing = this.canvasPadding();
            borderBounds = {
                minX: Math.max(minX-spacing, area.minX),
                minY: Math.max(minY-spacing, area.minY),
                maxX: Math.min(maxX+spacing, area.maxX),
                maxY: Math.min(maxY+spacing, area.maxY)
            }
        }

        

        let fillMove = {
            minX: 0,
            maxX: 0,
            minY: 0,
            maxY: 0
        }

        if(applyMinimum){
            //Evenly fill height if minimum is set
            //3 Steps: Check if needs filling -> fill biggest side until even -> fill both equally until full
            if(this.config.minHeightMM != 0){
                //Calculate height to fill
                let minPixel = this.config.minHeightMM * this.config.pixelToMM;

                //Equalize all sides if date is set
                if( this.patternController.dateIsSet() ){
                    let sapceTop = borderBounds.minY - area.minY;
                    let spaceBottom = area.maxY - borderBounds.maxY;
                    let minPixelEqualize = area.w - Math.min(sapceTop,spaceBottom) * 2;
                    minPixel = Math.max(minPixelEqualize, minPixel);
                }

                let h = borderBounds.maxY-borderBounds.minY;
                let heightDiff = minPixel-h;
                
                if(heightDiff > 0){
                    //calculate available space
                    let spaceTop = borderBounds.minY - area.minY;
                    let spaceBottom = area.maxY - borderBounds.maxY;

                    let sideDiff = spaceBottom - spaceTop;

                    let fillSize = 0;
                    //If theres more empty space on the bottom side
                    if(sideDiff > 0){
                        fillSize = Math.min(heightDiff, sideDiff);
                        fillMove.maxY += fillSize;
                    }
                    //If theres more empty space on the top side
                    if(sideDiff < 0){
                        fillSize = Math.min(heightDiff, -sideDiff);
                        fillMove.minY -= fillSize;
                    }

                    let remainingFillSize = heightDiff - fillSize;
                    if(remainingFillSize > 0){
                        //Fill size for both sides
                        fillMove.minY -= remainingFillSize/2;
                        fillMove.maxY += remainingFillSize/2;
                    }
                    //Limit to available area
                    fillMove.minY = Math.max(fillMove.minY, -spaceTop);
                    fillMove.maxY = Math.min(fillMove.maxY, spaceBottom);
                }
                
            }
            
            //Evenly fill width if minimum is set
            //3 Steps: Check if needs filling -> fill biggest side until even -> fill both equally until full
            if(this.config.minWidthMM != 0){
                //Calculate width to fill
                let minPixel = this.config.minWidthMM * this.config.pixelToMM;

                //Equalize all sides if date is set
                if( this.patternController.dateIsSet() ){
                    let spaceLeft = borderBounds.minX - area.minX;
                    let spaceRight = area.maxX - borderBounds.maxX;
                    let minPixelEqualize = area.w - Math.min(spaceLeft,spaceRight) * 2;
                    minPixel = Math.max(minPixelEqualize, minPixel);
                }

                let w = borderBounds.maxX-borderBounds.minX;
                let widthDiff = minPixel-w;
                
                if(widthDiff > 0){
                    //calculate available space
                    let spaceLeft = borderBounds.minX - area.minX;
                    let spaceRight = area.maxX - borderBounds.maxX;

                    let sideDiff = spaceRight - spaceLeft;

                    let fillSize = 0;
                    //If theres more empty space on the right side
                    if(sideDiff > 0){
                        fillSize = Math.min(widthDiff, sideDiff);
                        fillMove.maxX += fillSize;
                    }
                    //If theres more empty space on the left side
                    if(sideDiff < 0){
                        fillSize = Math.min(widthDiff, -sideDiff);
                        fillMove.minX -= fillSize;
                    }

                    let remainingFillSize = widthDiff - fillSize;
                    if(remainingFillSize > 0){
                        //Fill size for both sides
                        fillMove.minX -= remainingFillSize/2;
                        fillMove.maxX += remainingFillSize/2;
                    }
                    //Limit to available area
                    fillMove.minX = Math.max(fillMove.minX, -spaceLeft);
                    fillMove.maxX = Math.min(fillMove.maxX, spaceRight);
                }
            }


            //Apply fillmove
            minX += fillMove.minX;
            maxX += fillMove.maxX;
            minY += fillMove.minY;
            maxY += fillMove.maxY;

            borderBounds.minX += fillMove.minX;
            borderBounds.maxX += fillMove.maxX;
            borderBounds.minY += fillMove.minY;
            borderBounds.maxY += fillMove.maxY;
        }


        let noBorder = {
            'minX': minX,
            'maxX': maxX,
            'minY': minY,
            'maxY': maxY,
            'w': maxX-minX,
            'h': maxY-minY
        }
        
        return {
            'minX': borderBounds.minX,
            'maxX': borderBounds.maxX,
            'minY': borderBounds.minY,
            'maxY': borderBounds.maxY,
            'w': borderBounds.maxX-borderBounds.minX,
            'h': borderBounds.maxY-borderBounds.minY,
            noBorder: noBorder
        };
    }

    roundBounds(bounds: Bounds){
        let noBorder = {
            'minX': Math.floor(bounds.noBorder.minX),
            'maxX': Math.ceil(bounds.noBorder.maxX),
            'minY': Math.floor(bounds.noBorder.minY),
            'maxY': Math.ceil(bounds.noBorder.maxY),
            'w': bounds.noBorder.w,
            'h': bounds.noBorder.h
        }
        
        return {
            'minX': Math.floor(bounds.minX),
            'maxX': Math.ceil(bounds.maxX),
            'minY': Math.floor(bounds.minY),
            'maxY': Math.ceil(bounds.maxY),
            'w': bounds.w,
            'h': bounds.h,
            noBorder: noBorder
        };
    }

    //Calculate padding of the canvas
    canvasPadding(){
        let padding = 0;
        if(this.borderShows()){
            //Additional space for border
            let bw = (this.border.width*this.config.pixelToMM);
            padding = (this.border.padding*this.config.pixelToMM)+bw;
            if(this.border.double){
                //Add another border width and the distance to the basic border
                let dist = (this.config.pixelToMM)+bw;
                padding += dist;
            }
        }
        return padding;
    }

    //Get center coordinates
    boundsCenter(){
        let bounds = this.calcBounds(this.canvas);
        return {'x':bounds.minX+bounds.w/2, 'y':bounds.minY+bounds.h/2};
    }

    //Get center coordinates
    editAreaCenter(removePadding = false, upscale = false, canvas = this.canvas){
        let area = this.editAreaSize(removePadding, upscale, canvas);
        let w:number = area.minX+(area.w/2);
        let h:number = area.minY+(area.h/2);
        return {'x':w, 'y':h};
    }

    //Get the size of the canvas minus UI
    editAreaSize(removePadding = false, upscale = false, canvas = this.canvas){
        let minX = this.config.rulerX;
        let minY = this.config.rulerY;
        let w = canvas.width-(this.config.rulerSpaceEnd+this.config.rulerX);
        let h = canvas.height-(this.config.rulerSpaceEnd+this.config.rulerY);
        let maxX = minX + w;
        let maxY = minY + h;

        if(upscale){
            minX = 0;
            minY = 0;
            w = canvas.width;
            h = canvas.height;
            maxX = minX + w;
            maxY = minY + h;
        }

        if(removePadding){
            let padding = this.canvasPadding();
            minX += padding;
            minY += padding;
            maxX -= padding;
            maxY -= padding;
            w -= padding*2;
            h -= padding*2;
        }

        return {
            'w': w, 
            'h': h,
            minX: minX,
            minY: minY,
            maxX: maxX,
            maxY: maxY
        };
    }

    //BOUNDS END



    //Move elements vertically when changing font size
    resizeMove(resizeElement: DesignerText, size: number){
        size = Utility.pt2px(size, this.config.pixelToMM);
        size /= 2;
        resizeElement.moveRelative(0,-size,false,true);
        for(let element of this.elements){
            if(element != resizeElement){
                if(element.y < resizeElement.y){
                    element.moveRelative(0,-size,false,true);
                }
                if(element.y > resizeElement.y){
                    element.moveRelative(0,size,false,true);
                }
            }
        }
    }


    //Generate prewview with API
    async generatePreview(button: HTMLDivElement){
        if(!this.canvas){
            return false;
        }

        let errors = await this.displayErrorAlerts(false);
        if(errors){
            return false;
        }

        let imageData = this.getImageData(RenderMode.Preview);
        if(!imageData){
            console.error('Couldnt generate image data');
            return false;
        }

        let bounds = this.calcBounds();

        let bonusParams = {
            newDesigner: true,
            MCI: this.MCI,
            userID: this.config.userID,
            pixelToMM: this.config.pixelToMMUpload,
            tid: this.config.dataset.tid,
            width: Math.ceil(bounds.w/this.config.pixelToMM) ,
            height: Math.ceil(bounds.h/this.config.pixelToMM) ,
            color: this.inkpadColor,
            article: this.config.dataset.artikel,
            imageData: imageData
        };
        
        button.classList.add("pdf_loading");
        let span = button.getElementsByTagName("span")[0];
        span.textContent = "Lade...";

        this.API.request("loadproof", bonusParams).then(
            (response:APIResponse) => {
                if(response.responseCode != 'success'){
                    console.error(response.data);
                    return;
                }
                button.dataset.url = response.data;
                button.classList.remove("pdf_loading");
                button.classList.add("pdf_ready");
                span.textContent = "Vorschau öffnen";
            }
        ,(error) => {
            console.error(error);
            return false;
        });
        
    }

    //Adjust element values for pixel scale change
    changePixelScaling(newPixelToMM: number, keepUISpace = false){
        let scaleFactor = newPixelToMM / this.config.pixelToMM;
        this.config.pixelToMM = newPixelToMM;

        for(let element of this.elements){
            if(keepUISpace){
                element.x -= this.config.rulerX;
                element.y -= this.config.rulerY;
            }
            
    
            element.x *= scaleFactor;
            element.y *= scaleFactor;
    
            element.w *= scaleFactor;
            element.h *= scaleFactor;
    
            if(element instanceof DesignerDate){
                element.rectH *= scaleFactor;
                element.rectW *= scaleFactor;
            }

            if(element instanceof DesignerText){
                element.getDimensions();
            }
        }

        if(keepUISpace){
            for(let element of this.elements){
                element.x += this.config.rulerX;
                element.y += this.config.rulerY;
            }
        }
    }
    

    //Extract image data from canvas in bounds
    getImageData(renderMode: RenderMode){
        //Set pixel ratio
        let scaleFactor = this.config.pixelToMMUpload / this.config.pixelToMM;
        let oldPixel = this.config.pixelToMM;

        let canvasData = this.createCanvas("upscale_canvas");
        if(!canvasData){
            return false;
        }

        //temporary invisible canvas
        let upscaleCanvas = canvasData.canvas;
        upscaleCanvas.width = this.config.maxWidthMM*this.config.pixelToMMUpload;
        upscaleCanvas.height = this.config.maxHeightMM*this.config.pixelToMMUpload;

        //Debug - show upscale canvas
        if(this.config.showUpscaleCanvasDebug){
            this.designerDiv.appendChild(upscaleCanvas);
        }

        //For pixel perfect cut line - no idea why this only works when translated twice ...
        canvasData.context.translate(-0.5,-0.5);

        let area = this.editAreaSize();

        //Set pixel ratio and positions
        //Remove UI space
        for(let element of this.elements){
            element.x -= area.minX;
            element.y -= area.minY;
        }

        //Move elements
        this.changePixelScaling(this.config.pixelToMMUpload);

        //Resize images and upload colored versions
        for(let element of this.elements){
            if(element instanceof DesignerImage){
                element.resize(element.w, element.h, true);
            }
        }

        let bounds = this.renderer.renderUpscale(upscaleCanvas, scaleFactor, renderMode);
        if(!bounds){
            console.error('Couldnt render upscaled image');
            return false;
        }
        //this.render(true);
        let imageData = this.crop(bounds, upscaleCanvas);

        //Reset pixel ratio and positions
        this.changePixelScaling(oldPixel);

        //Add back UI space
        for(let element of this.elements){
            element.x += this.config.rulerX;
            element.y += this.config.rulerY;
        }

        return imageData;
    }

    //crop data
    crop(bounds: Bounds, canvas: HTMLCanvasElement) {
        // get the image data you want to keep.
        let ctx = canvas.getContext('2d');
        if(!ctx){
            console.error('Couldnt get canvas context');
            return false;
        }
        
        var imageData = ctx.getImageData(bounds.minX,bounds.minY,bounds.maxX,bounds.maxY);
      
        // create a new cavnas same as clipped size and a context
        var newCan = document.createElement('canvas');
        newCan.width = bounds.w;
        newCan.height = bounds.h;
        var newCtx = newCan.getContext('2d');
      
        // put the clipped image on the new canvas.
        newCtx?.putImageData(imageData, 0, 0);
        return newCan.toDataURL('image/png');
    }

    //Load save data from JSON string
    loadHistoryPoint(saveDataJSON: string){
        let data:SaveObject = JSON.parse(saveDataJSON);
        this.patternController.loadSaveData(data, true, true, false, false, true);
        this.setLocalSave(saveDataJSON);
    }

    //Trigger alignment for all elements
    alignElements(){
        //Align = repeat until elements arent moving anymore, maximum 50 steps for now
        for(let i = 0;i < 50; i ++){
            let elementsMoved = 0;
            for(let element of this.elements){
                let moved = element.applyAlignment();
                if(moved){
                    elementsMoved ++;
                }
            }
            //If no elements moved this step, end the loop early
            if(elementsMoved == 0){
                break;
            }
        }
    }

    //Reset alignment for all elements
    resetAlignments(){
        for(let element of this.elements){
            element.resetAlignment();
        }
    }

    //Find an element by the pattern id from legacy patterns
    findElementByPatternID(patternID: string){
        for(let element of this.elements){
            //Skip non text elements
            if(!(element instanceof DesignerText)){
                continue;
            }
            if(element.patternID == patternID){
                return element;
            }
        }
        return false;
    }

    //PATTERN HANDELING END



    //API Request to canvas
    async loadFontList(){
        let _this = this;
        return new Promise(function (resolve, reject) {
            let bonusParams = {
                'artikel_nr' : _this.config.dataset.artikel
            }
            _this.API.request("loadfont", bonusParams).then(
                async (data:Array<FontObject>) => {
                    if(!data){
                        reject("No data");
                        return;
                    }
                    //Load promises in Async
                    let promises:Array< Promise<any> > = [];
                    for(let font of data){
                        promises.push(_this.loadFont(font));
                    }
                    await Promise.allSettled(promises).then( () => {
                        _this.fonts.sort();
                        _this.tabInterface.renderTextTab();
                        
                        resolve("Loading done");
                    });
                }
            ,(error) => {
                console.error(error);
                reject(error);
            });
        });
    }

    //Initiate loading of font family
    async loadFont(font:FontObject){
        if(font.style.normal && font.style.normal !== "undefined"){
            let url = 'url('+this.config.fontPath+font.style.normal+'.ttf)';
            await this.API.requestFont(font.name, url);
        }
        if(font.style.fett && font.style.fett !== "undefined"){
            let url = 'url('+this.config.fontPath+font.style.fett+'.ttf)';
            await this.API.requestFont(font.name, url, {weight: 'bold'});
            this.fontsBold.push(font.name);
        }
        if(font.style.kursiv && font.style.kursiv !== "undefined"){
            let url = 'url('+this.config.fontPath+font.style.kursiv+'.ttf)';
            await this.API.requestFont(font.name, url, {style: 'italic'});
            this.fontsItalic.push(font.name);
        }
        this.fonts.push(font.name);
    }

    //Center content, have same empty space all around
    centerContent(applyMinimum = true, ignoreStatic = true){
        let area = this.editAreaSize();
        let bounds = this.calcBounds(this.canvas, false, applyMinimum, true);

        let xLeft = bounds.minX - area.minX;
        let xRight = area.maxX - bounds.maxX;

        let xDelta = (xRight - xLeft)/2;

        let yTop = bounds.minY - area.minY;
        let yBottom = area.maxY - bounds.maxY;

        let yDelta = (yBottom - yTop)/2;

        for(let element of this.elements){
            //Dont move date
            /*
            if(ignoreStatic && element.static){
                continue;
            }*/
            if(element.type == "date"){
                continue;
            }
            element.x += xDelta;
            element.y += yDelta;
        }
        this.update(false,false);
        return true;
    }

    //Auto-center content after movement if bounds changed - always keep it symetrical and allow easy edge dragging with images
    autoCenter(oldBounds: Bounds, newBounds: Bounds){
        //Dont move with dater existing
        if(this.patternController.dateIsSet()){
            return false;
        }
        let deltaminX = oldBounds.minX - newBounds.minX;
        let deltamaxX = newBounds.maxX - oldBounds.maxX;
        let deltaminY = oldBounds.minY - newBounds.minY;
        let deltamaxY = newBounds.maxY- oldBounds.maxY;

        if(deltaminX != 0 || deltamaxX != 0 || deltaminY != 0 || deltamaxY != 0){
            return this.centerContent();
        }
    }

    //Check if border should be displayed
    borderShows():boolean{
        if(this.border.active && this.border.width > 0){
            return true;
        }
        return false;
    }

    //Show message box above designer
    showMessage(type: "" | "error" | "success" | "warning", text: string, hideAfterTimer = true){
        let alert = "alert-secondary";
        if(type == "error"){
            alert = "alert-danger";
        }
        if(type == "success"){
            alert = "alert-success";
        }
        if(type == "warning"){
            alert = "alert-warning";
        }

        let HTML = `<div class="alert ${alert} alert-dismissible fade show" role="alert"> 
            ${text}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>`;

        let alertDIV = document.createElement('DIV');
        alertDIV.innerHTML = HTML;

        let closeButton = alertDIV.querySelector(".close");

        closeButton?.addEventListener('click', () => {
            alertDIV.remove();
        })

        let popupDIV = document.getElementById("designer_alerts");
        if(!popupDIV){
            console.error('No designer_alerts DIV! Cant show messages');
            return false;
        }

        let existingAlerts = popupDIV.querySelectorAll(".alert");
        for(let alert of existingAlerts){
            let content = alert.innerHTML;
            if(content.includes(text)){
                return false;
            }
        }

        //Hide after X seconds with animation
        if(hideAfterTimer){
            setTimeout(() => {
                alertDIV.classList.add("startPopOut");
                setTimeout(() => {
                    alertDIV.remove();
                }, 1*1000)
            }, this.config.hideNotificationTimer*1000);
        }
        
        popupDIV.prepend(alertDIV);
        return true;
    }

    //Show a error message in the alert box
    showErrorMessage(error: ElementError){
        let msg = Utility.getErrorMessage(error);
        this.showMessage("error", "<b> Fehler! </b> "+msg);
    }

    //Scale everyhting in the edit area
    scaleContent(newScale: number){
        let previousScale = this.scaling;
        this.scaling = newScale;
        let scaleChange = newScale - previousScale;

        //only scale for noticable changes
        if(Math.abs(scaleChange) < 0.01){
            return false;
        }

        let area = this.editAreaSize();

        //Reset scaling by diving by last scaling, so changes wont become exponential (1.1 * 1.1 = 1.21)
        //Border
        this.setPrescaleProperties(previousScale);

        //Elements
        for(let element of this.elements){
            //Ignore date
            if( element instanceof DesignerDate ){
                continue;
            }
            //Image
            if(element instanceof DesignerImage){
                element.x = ( (element.x-area.minX)*newScale ) + area.minX;
                element.y = ( (element.y-area.minY)*newScale ) + area.minY;

                element.w *= newScale;
                element.h *= newScale;
            }

            if(element instanceof DesignerText){
                let offsetX = element.preScale.relativeX * newScale;
                let offsetY = element.preScale.relativeY * newScale;

                element.x = offsetX + area.minX;
                element.y = offsetY + area.minY;

                //Only apply to text if it doesn't hit minimum or maximum
                let elementScale = newScale;
                if(element.preScale.size * newScale < this.config.fontMinSize){
                    elementScale = this.config.fontMinSize / element.preScale.size;
                }
                if(element.preScale.size * newScale > this.config.fontMaxSize){
                    elementScale = this.config.fontMaxSize / element.preScale.size;
                }

                element.setSize(element.preScale.size * elementScale, false, false);
                element.setDimensions(false);
            }

            if(element instanceof DesignerImage){
                let offsetX = element.preScale.relativeX * newScale;
                let offsetY = element.preScale.relativeY * newScale;

                element.x = offsetX + area.minX;
                element.y = offsetY + area.minY;

                element.w = element.preScale.w * newScale;
                element.h = element.preScale.h * newScale;
            }

            if(element instanceof DesignerRect){
                let elementScale = newScale;
                
                if(element.preScale.width * newScale < this.config.lineMinWidth * this.config.pixelToMM){
                    elementScale = (this.config.lineMinWidth * this.config.pixelToMM) / element.preScale.width;
                }
                if(element.preScale.width * newScale > this.config.lineMaxWidth * this.config.pixelToMM){
                    elementScale = (this.config.lineMaxWidth * this.config.pixelToMM) / element.preScale.width;
                }
                let offsetX = element.preScale.relativeX * newScale;
                let offsetY = element.preScale.relativeY * newScale;

                element.x = offsetX + area.minX;
                element.y = offsetY + area.minY;

                element.setWidth( element.preScale.width * elementScale, false);
                element.setLength( element.preScale.length * newScale, false);
            }
            
            element.changed = false;
        }

        //Border
        if(this.preScaleBorderWidth != null && this.preScaleBorderPadding != null){
            this.tabInterface.setBorderWidth(this.preScaleBorderWidth * newScale);
            this.tabInterface.setBorderPadding(this.preScaleBorderPadding * newScale);
        }

        this.centerContent(false);
        this.centerContent(true, false);

        this.update(true, true, true);

        return true;
    }

    //Set prescale properties for scaling calculation
    setPrescaleProperties(previousScale: number){
        let bounds = this.calcBounds();

        if(this.preScaleBorderWidth == null){
            this.preScaleBorderWidth = this.border.width;
        }
        if(this.preScaleBorderPadding == null){
            this.preScaleBorderPadding = this.border.padding;
        }

        //Set basic position data before the rest
        for(let element of this.elements){
            if( !(element instanceof DesignerDate) ){
                if(element.preScale == null){
                    element.preScale = {};
                    element.preScale.x = element.x;
                    element.preScale.y = element.y;
                    element.preScale.relativeX = (element.preScale.x-bounds.minX) / previousScale;
                    element.preScale.relativeY = (element.preScale.y-bounds.minY) / previousScale;
                }
            }
        }

        let recalculatedRelativePos = false;
        for(let element of this.elements){
            if( !(element instanceof DesignerDate) ){
                if(element.changed == true){
                    //Bounds may have changed, recalculate relative posiiton
                    //Only do it once
                    if(!recalculatedRelativePos){
                        for(let element1 of this.elements){
                            if(!element1.static){
                                element1.preScale.x = element1.x;
                                element1.preScale.y = element1.y;
                                element1.preScale.relativeX = (element1.preScale.x-bounds.minX) / previousScale;
                                element1.preScale.relativeY = (element1.preScale.y-bounds.minY) / previousScale;
                            }
                        }
                    }
                    recalculatedRelativePos = true;

                    //Set prescale properties, calculate initial size when scaling already active
                    if(element instanceof DesignerText){
                        element.preScale.size = element.size / previousScale;
                    }
                    if(element instanceof DesignerRect){
                        element.preScale.width = element.getWidth() / previousScale;
                        element.preScale.length = element.getLength() / previousScale;
                    }
                    if(element instanceof DesignerImage){
                        element.preScale.w = element.w / previousScale;
                        element.preScale.h = element.h / previousScale;
                    }
                    element.changed = false;
                }
            }
        }
    }

    calculateFill(ignoreLimit = false){
        let bounds = this.calcBounds();
        let area = this.editAreaSize();

        //Calculate with scaling 1
        let wFilled = (bounds.w / this.scaling) / area.w;
        let hFilled = (bounds.h / this.scaling) / area.h;

        let firstFilled = Math.max(wFilled, hFilled);

        //Slight error range
        firstFilled += 0.01;

        let fillRatio = 1 / firstFilled;
        
        //Round to 0.1
        fillRatio = Math.floor(fillRatio*10)/10;

        //Clamp scaling between slider range unless limit is ignored
        if(!ignoreLimit){
            fillRatio = Utility.clamp(fillRatio, 0.5, 2);
        }

        return fillRatio;
    }

    scaleFill(ignoreLimit = false){
        let fillRatio = this.calculateFill(ignoreLimit);

        //Only increase scaling, never decrease
        if(fillRatio < 1.0){
            return false;
        }

        if( this.scaleContent(fillRatio) ){
            this.scaleFill(true);
        }
    }
}

export {DesignerController};